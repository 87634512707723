<template>
  <div class="ACE1Pro">
    <div class="banner">
      <el-carousel :interval="5000" arrow="always">
        <el-carousel-item>
          <div class="itemone cursor" @click="goRouter('/ACE1Pro')">
            <div class="title">ACE 1 Pro</div>
            <div class="text">Above mainstream performance</div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="itemtwo cursor" @click="goRouter('/ACE1')">
            <div class="title">ACE 1</div>
            <div class="text">Extremely narrow bezels</div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="itemthree cursor" @click="goRouter('/MK215')">
            <div class="title">MK 215</div>
            <div class="text">Customizable design and features</div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="itemfour cursor" @click="goRouter('/ET7')">
            <div class="title">ET7</div>
            <div class="text">Smart AI Barcode Scale</div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <ImgVideo :video="video" :img="img"></ImgVideo>
    <div class="productDisplay">
      <div class="content w">
        <div class="list">
          <div class="itemA">
            <div class="left">
              <img
                src="../../../assets/官网首页配图/第三页产品介绍图/1.png"
                alt=""
              />
            </div>
            <div class="right">
              <img
                src="../../../assets/官网首页配图/第三页产品介绍图/2.png"
                alt=""
              />
            </div>
          </div>
          <div class="itemB">
            <img
              src="../../../assets/官网首页配图/第三页产品介绍图/3.png"
              alt=""
            />
          </div>
          <div class="itemA">
            <div class="left">
              <img
                src="../../../assets/官网首页配图/第三页产品介绍图/4.png"
                alt=""
              />
            </div>
            <div class="right">
              <img
                src="../../../assets/官网首页配图/第三页产品介绍图/5.png"
                alt=""
              />
            </div>
          </div>
          <div class="itemB">
            <img
              src="../../../assets/官网首页配图/第三页产品介绍图/6.png"
              alt=""
            />
          </div>
          <div class="itemA">
            <div class="left">
              <img
                src="../../../assets/官网首页配图/第三页产品介绍图/7.png"
                alt=""
              />
            </div>
            <div class="right">
              <img
                src="../../../assets/官网首页配图/第三页产品介绍图/8.png"
                alt=""
              />
            </div>
          </div>
          <!-- <div class="itemA">
            <div class="left">
              <img
                src="../../../assets/官网首页配图/第三页产品介绍图/1.png"
                alt=""
              />
            </div>
            <div class="right">
              <img
                src="../../../assets/官网首页配图/第三页产品介绍图/2.png"
                alt=""
              />
            </div>
          </div> -->
        </div>
        <div class="textContent">
          <div class="title">Product Display</div>
          <div class="text">
            The product stands out in both functionality and aesthetics. Its
            display screen instantly updates purchase details, streamlining
            ordering and payment. It also features promotional content, guiding
            customers to explore more services and membership offers.
          </div>
          <div class="button">
            <div
              :class="this.moveIndex <= 0 ? 'butOFF' : 'butOn'"
              @click="moveRight"
            >
              <i class="el-icon-arrow-left icon"></i>
            </div>
            <div
              :class="this.moveIndex >= 3 ? 'butOFF' : 'butOn'"
              @click="moveLeft"
            >
              <i class="el-icon-arrow-right icon"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="performance">
      <div class="w">
        <div class="title">Above mainstream performance</div>
        <div class="bottom">
          <div class="left">
            <div class="title">Quad-core Cortex A55</div>
            <div class="text">CPU</div>
          </div>
          <div class="centent">
            <div class="title">2.0 GHz Max</div>
            <div class="text">cpu clock speed</div>
          </div>
          <div class="right">
            <div class="title">4G DDR4+32G eMCC</div>
            <div class="text">Memory</div>
          </div>
        </div>
      </div>
    </div>
    <div class="display">
      <div class="top">
        <div class="title">Single and double , more combinations</div>
        <div class="text">Wide angle adjustable screen</div>
      </div>
      <div class="left">
        <div class="title">Main Display</div>
        <div class="text">15.6” 1920 x 1080</div>
        <div class="text">Multi-touch capacitive screen</div>
      </div>
      <div class="right">
        <div class="title">Secondary Display</div>
        <div class="text">10.1” 1280 x 800</div>
      </div>
    </div>
    <div class="extremely">
      <div class="con w">
        <div class="textBox">
          <div class="title">Extremely narrow bezels</div>
          <div class="text">The thinnest part is only 8mm</div>
        </div>
      </div>
    </div>
    <div class="anodised">
      <div class="con w">
        <div class="textBox">
          <div class="title">Anodised Aluminium</div>
        </div>
      </div>
    </div>
    <div class="printer">
      <div class="title">80mm High-speed printer</div>
    </div> -->
    <!-- <div class="downloads">
      <div class="titles">Supports and Downloads</div>
      <div class="w">
        <div class="itemBox">
          <div class="itemA">
            <div class="title">ACE 1</div>
            <div class="text">ZIP(20MB)</div>
            <div class="icon"><i class="el-icon-bottom"></i></div>
          </div>
          <div class="itemA">
            <div class="title">ACE 1 Pro</div>
            <div class="text">ZIP(19MB)</div>
            <div class="icon"><i class="el-icon-bottom"></i></div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import ImgVideo from '@/components/imgVideo/index'
import img from '@/assets/官网首页配图/第二页动图.gif'
import video from '@/assets/MK215页面配图/Compax_Mk_215_Video.mp4'
export default {
  data() {
    return {
      video: video,
      img: img,
      moveIndex: 0,
      timer: true //节流
    }
  },
  components: {
    ImgVideo
  },
  methods: {
    moveLeft() {
      if (this.timer) {
        if (this.moveIndex >= 3) {
          return
        }
        this.moveIndex += 1
        const listEl = document.querySelector('.list')
        const itemA = document.querySelector('.itemA')
        const itemB = document.querySelector('.itemB')
        const itemAWidth = this.getElementWidthWithMargin(itemA)
        const itemBWidth = this.getElementWidthWithMargin(itemB)
        if (this.isEvenOrOdd(this.moveIndex) == '奇数') {
          listEl.style.left =
            parseFloat(window.getComputedStyle(listEl, null).left) -
            itemAWidth +
            'px'
        } else {
          listEl.style.left =
            parseFloat(window.getComputedStyle(listEl, null).left) -
            itemBWidth +
            'px'
        }
        this.timer = false
        setTimeout(() => {
          this.timer = true
        }, 1000)
      }
    },
    moveRight() {
      if (this.timer) {
        if (this.moveIndex <= 0) {
          return
        }
        this.moveIndex -= 1
        const listEl = document.querySelector('.list')
        const itemA = document.querySelector('.itemA')
        const itemB = document.querySelector('.itemB')
        const itemAWidth = this.getElementWidthWithMargin(itemA)
        const itemBWidth = this.getElementWidthWithMargin(itemB)
        if (this.isEvenOrOdd(this.moveIndex) == '偶数') {
          listEl.style.left =
            parseFloat(window.getComputedStyle(listEl, null).left) +
            itemAWidth +
            'px'
        } else {
          listEl.style.left =
            parseFloat(window.getComputedStyle(listEl, null).left) +
            itemBWidth +
            'px'
        }
        this.timer = false
        setTimeout(() => {
          this.timer = true
        }, 1000)
      }
    },
    getElementWidthWithMargin(element) {
      var style = window.getComputedStyle(element)
      var margin = parseFloat(style.marginLeft) + parseFloat(style.marginRight)
      return element.offsetWidth + margin
    },
    isEvenOrOdd(num) {
      if (num % 2 === 0) {
        return '偶数'
      } else {
        return '奇数'
      }
    },
    goRouter(val) {
      this.$router.push({ path: val })
    }
  }
}
</script>

<style lang="less" scoped>
.ACE1Pro {
  .cursor {
    cursor: pointer;
  }
  .banner {
    /deep/ .el-carousel__arrow--left {
      top: 50%;
      transform: translateY(-50%);
      left: 215px;
      font-size: 72px;
      background: transparent;
    }
    /deep/ .el-carousel__arrow--right {
      top: 50%;
      transform: translateY(-50%);
      right: 215px;
      font-size: 72px;
      background: transparent;
    }
    /deep/ .el-carousel__indicators--horizontal {
      bottom: 40px;
    }
    /deep/ .el-carousel__button {
      width: 130px;
      height: 2px;
      border-radius: 2px;
    }
    /deep/.el-carousel__container {
      height: calc(100vh - 80px);
    }
    .itemone {
      text-align: center;
      padding-top: 116px;
      width: 100%;
      height: 100%;
      background: url('../../../assets/官网首页配图/第一页轮播图/ACE 1 Pro.png')
        no-repeat center;
      background-size: cover;
      .title {
        line-height: 86.88px;
        color: rgba(0, 92, 255, 1);
        font-size: 60px;
        font-weight: 700;
      }
      .text {
        color: rgba(255, 255, 255, 1);
        line-height: 52.13px;
        font-size: 36px;
        font-weight: 500;
      }
    }
    .itemtwo {
      text-align: center;
      padding-top: 116px;
      width: 100%;
      height: 100%;
      background: url('../../../assets/官网首页配图/第一页轮播图/ACE1.png')
        no-repeat center;
      background-size: cover;
      .title {
        line-height: 86.88px;
        color: rgba(0, 92, 255, 1);
        font-size: 60px;
        font-weight: 700;
      }
      .text {
        color: rgba(255, 255, 255, 1);
        line-height: 52.13px;
        font-size: 36px;
        font-weight: 500;
      }
    }
    .itemthree {
      text-align: center;
      padding-top: 116px;
      width: 100%;
      height: 100%;
      background: url('../../../assets/官网首页配图/第一页轮播图/MK215.png')
        no-repeat center;
      background-size: cover;
      .title {
        line-height: 86.88px;
        color: rgba(0, 92, 255, 1);
        font-size: 60px;
        font-weight: 700;
      }
      .text {
        color: rgba(255, 255, 255, 1);
        line-height: 52.13px;
        font-size: 36px;
        font-weight: 500;
      }
    }
    .itemfour {
      text-align: center;
      padding-top: 116px;
      width: 100%;
      height: 100%;
      background: url('../../../assets/官网首页配图/第一页轮播图/ET7.png')
        no-repeat center;
      background-size: cover;
      .title {
        line-height: 86.88px;
        color: rgba(0, 92, 255, 1);
        font-size: 60px;
        font-weight: 700;
      }
      .text {
        color: rgba(255, 255, 255, 1);
        line-height: 52.13px;
        font-size: 36px;
        font-weight: 500;
      }
    }
  }
  .productDisplay {
    overflow: hidden;
    padding-top: 94px;
    background: rgba(0, 0, 0, 1);
    height: 1080px;
    .content {
      //overflow: hidden;
      .list {
        // transform: all 2s;
        transition: left 1s;
        position: relative;
        left: 0px;
        display: flex;
        position: relative;
        .itemA {
          flex-shrink: 0;
          width: 620px;
          height: 740px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-right: 40px;
          img {
            // border-radius: 20px;
            width: 100%;
            height: 100%;
          }
          .left {
            // border: 2px solid rgba(128, 128, 128, 1);
            border-radius: 20px;
            width: 620px;
            height: 350px;
          }
          .right {
            // border: 2px solid rgba(128, 128, 128, 1);
            border-radius: 20px;
            width: 620px;
            height: 350px;
          }
        }
        .itemB {
          flex-shrink: 0;
          width: 540px;
          height: 740px;
          margin-right: 40px;
          img {
            // border-radius: 20px;
            width: 100%;
            height: 100%;
          }
        }
      }
      .textContent {
        width: 100%;
        padding: 50px 26px 0px;
        justify-content: space-between;
        display: flex;
        .title {
          color: rgba(0, 92, 255, 1);
          font-size: 30px;
          font-weight: 700;
        }
        .text {
          width: 672px;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          font-weight: 500;
          line-height: 25px;
        }
        .button {
          width: 123px;
          display: flex;
          justify-content: space-between;
          .butOn {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            color: rgba(255, 255, 255, 1);
            background: rgba(0, 92, 255, 1);
            width: 54px;
            height: 54px;
            border-radius: 50%;
          }
          .butOFF {
            cursor: pointer;
            display: flex;
            justify-content: center;
            color: rgba(255, 255, 255, 1);
            align-items: center;
            background: rgba(166, 166, 166, 1);
            width: 54px;
            height: 54px;
            border-radius: 50%;
          }
          .icon {
            font-size: 32px;
          }
        }
      }
    }
  }
  .performance {
    padding-top: 61px;
    background: url('../../../assets/官网首页配图/第四页配图.png') no-repeat
      center;
    background-size: cover;
    height: 900px;
    .title {
      color: rgba(255, 255, 255, 1);
      text-align: center;
      font-size: 48px;
      font-weight: 700;
      line-height: 69.5px;
    }
    .bottom {
      padding: 623px 46px 0px;
      display: flex;
      justify-content: space-between;
      .title {
        margin-bottom: 8px;
        font-size: 36px;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        line-height: 52.13px;
      }
      .text {
        font-size: 20px;
        font-weight: 500;
        line-height: 25px;
        color: rgba(0, 92, 255, 1);
      }
    }
  }
  .display {
    position: relative;
    padding-top: 158px;
    height: 1080px;
    background: url('../../../assets/官网首页配图/第五页配图.png') no-repeat
      center;
    background-size: cover;
    .top {
      text-align: center;
      .title {
        font-size: 48px;
        font-weight: 700;
        color: rgba(255, 255, 255, 1);
      }
      .text {
        font-size: 36px;
        font-weight: 500;
        color: rgba(0, 92, 255, 1);
      }
    }
    .left {
      position: absolute;
      top: 779px;
      left: 495px;
      width: fit-content;
      text-align: center;
      .title {
        margin-bottom: 19px;
        font-size: 36px;
        height: 46px;
        font-weight: 700;
        color: rgba(0, 92, 255, 1);
      }
      .text {
        color: rgba(255, 255, 255, 1);
        font-size: 24px;
        line-height: 30px;
      }
    }
    .right {
      position: absolute;
      top: 779px;
      left: 1095px;
      width: fit-content;
      text-align: center;
      .title {
        margin-bottom: 19px;
        height: 46px;
        font-size: 36px;
        font-weight: 700;
        color: rgba(0, 92, 255, 1);
      }
      .text {
        color: rgba(255, 255, 255, 1);
        font-size: 24px;
        line-height: 30px;
      }
    }
  }
  .extremely {
    height: 1080px;
    background: url('../../../assets/官网首页配图//第六页配图.png') no-repeat
      center;
    background-size: cover;
    .con {
      position: relative;
      .textBox {
        position: absolute;
        top: 106px;
        right: 118px;
        text-align: end;
        .title {
          color: rgba(255, 255, 255, 1);
          font-size: 48px;
          font-weight: 700;
          margin-bottom: 20px;
        }
        .text {
          color: rgba(255, 255, 255, 1);
          font-size: 30px;
        }
      }
    }
  }
  .anodised {
    height: 1080px;
    background: url('../../../assets/官网首页配图/第七页配图.png') no-repeat
      center;
    background-size: cover;
    .con {
      position: relative;
      .textBox {
        position: absolute;
        top: 174px;
        right: 118px;
        text-align: end;
        .title {
          color: rgba(255, 255, 255, 1);
          font-size: 48px;
          font-weight: 700;
          margin-bottom: 20px;
        }
      }
    }
  }
  .printer {
    padding-top: 214px;
    height: 1080px;
    background: url('../../../assets/官网首页配图/第八页动图.gif') no-repeat
      center;
    background-size: cover;
    .title {
      color: rgba(255, 255, 255, 1);
      font-size: 48px;
      font-weight: 700;
      text-align: center;
    }
  }
  .downloads {
    background: rgba(0, 0, 0, 1);
    .titles {
      color: rgba(255, 255, 255, 1);
      text-align: center;
      padding: 68px 0px 41px;
      font-size: 48px;
      font-weight: 700;
    }
    .itemBox {
      display: flex;
      margin-top: 21px;
      padding: 0px 10px;
      .title {
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        color: rgba(255, 255, 255, 1);
        margin-bottom: 5px;
      }
      .text {
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        color: rgba(128, 128, 128, 1);
      }
      .itemA {
        margin-left: 20.22px;
        padding: 19px 0px 0px 26px;
        width: 220px;
        height: 140px;
        opacity: 1;
        border-radius: 20px;
        background: rgba(27, 32, 48, 1);
        .icon {
          border-radius: 50%;
          color: rgba(0, 92, 255, 1);
          margin-top: 9px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 36px;
          height: 36.55px;
          border: 1px solid rgba(0, 92, 255, 1);
        }
      }
    }
  }
}
</style>
